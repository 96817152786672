import React from "react";
import { motion } from "framer-motion";
import "./styles/blue-pages.css";
import Nav from "./nav";

function Meaning() {
  return (
    <motion.div
      className="blue"
      initial={{
        opacity: 0,
        x: "-100vw",
      }}
      animate={{
        opacity: 1,
        x: 0,
      }}
      exit={{
        opacity: 0,
        x: "-100vw",
      }}
    >
      <Nav />
      <div className="sec1">
        <div className="meaning-preview"> </div>{" "}
        <div>
          {" "}
          <h1>What Does Success Really Mean?</h1>{" "}
        </div>{" "}
      </div>{" "}
      <div className="sec2">
        <div>
          {" "}
          <p>Success can be defined differently by everyone.</p>{" "}
        </div>{" "}
        <div className="svg different-people"> </div>{" "}
      </div>{" "}
      <div className="sec3">
        <div className="svg goal"> </div>{" "}
        <div>
          <p>
            In my eyes, success is achieving the goal you set at the beginning.
          </p>
        </div>{" "}
      </div>{" "}
      <div className="sec4">
        <div>
          {" "}
          <p>Whether it be winning the race, </p>{" "}
        </div>{" "}
        <div className="svg running"> </div>{" "}
      </div>{" "}
      <div className="sec5">
        <div className="svg love"> </div>{" "}
        <div>
          {" "}
          <p> getting the girl, </p>{" "}
        </div>{" "}
      </div>{" "}
      <div className="sec6">
        <div>
          {" "}
          <p> or getting the job. </p>{" "}
        </div>{" "}
        <div className="svg job"> </div>{" "}
      </div>{" "}
      <div className="sec7">
        <div>
          <h4>Success is doing.</h4>
          <p>
            Not simply watching. Not working haphazardly. Success is doing.
          </p>{" "}
        </div>{" "}
        <div className="svg holding-the-world"> </div>{" "}
      </div>{" "}
      <footer>
        <div>
          <a className="footer-link" href="/">
            back{" "}
          </a>{" "}
        </div>{" "}
        <div>
          "Success is a function of persistence and doggedness and the
          willingness to work hard for twenty - two minutes to make sense of
          something that most people would give up on after thirty seconds. " -
          Malcolm Gladwell{" "}
        </div>{" "}
        <div>
          Next :{" "}
          <a className="footer-link" href="/measuring">
            How do you measure success ?
          </a>{" "}
        </div>{" "}
      </footer>{" "}
    </motion.div>
  );
}

export default Meaning;

import React from "react";
import { motion } from "framer-motion";
import "./styles/blue-pages.css";
import Nav from "./nav";

function Measuring() {
  return (
    <motion.div
      className="pink"
      initial={{
        opacity: 0,
        x: "-100vw",
      }}
      animate={{
        opacity: 1,
        x: 0,
      }}
      exit={{
        opacity: 0,
        x: "-100vw",
      }}
    >
      <Nav />
      <div className="sec1">
        <div className="measurement-preview"> </div>{" "}
        <div>
          <h1> How do you measure success?</h1>{" "}
        </div>{" "}
      </div>{" "}
      <div className="sec2">
        <div>
          {" "}
          <p>Some think its</p> <h3>Money, Fame,</h3> <p>or</p> <h3>Power.</h3>{" "}
        </div>{" "}
        <div className="svg money"> </div>{" "}
      </div>{" "}
      <div className="sec3">
        <div className="svg goals"> </div>{" "}
        <div>
          <p>
            Success is really about whether or not you have fulfilled your
            hardest goals and ambitions.
          </p>
        </div>{" "}
      </div>{" "}
      <div className="sec4">
        <div>
          <p>Some use</p> <h3> Jeff Bezos </h3>{" "}
          <p> as an example of someone successful because of his</p>{" "}
          <h3>Money</h3>
        </div>{" "}
        <div className="svg determination"> </div>{" "}
      </div>{" "}
      <div className="sec5">
        <div className="svg randomness"> </div>{" "}
        <div>
          <p>Others say </p>
          <h3> Barak Obama </h3>
          <p> for his success in</p>
          <h3>breaking barriers</h3>
        </div>{" "}
      </div>{" "}
      <div className="sec6">
        <div>
          <p>Even more, would say that </p>
          <h3> Jim Halpert </h3>
          <p>from the office is successful in finding</p>
          <h3>true love.</h3>
        </div>{" "}
        <div className="svg job"> </div>{" "}
      </div>{" "}
      <div className="sec7">
        <div>
          <p>And, I would agree with all of them. </p>
        </div>{" "}
        <div className="svg holding-the-world"> </div>{" "}
      </div>{" "}
      <footer>
        <div>
          <a className="footer-link" href="/">
            back{" "}
          </a>{" "}
        </div>{" "}
        <div>
          {" "}
          “ When something is important enough, you do it even if the odds are
          not in your favor.” - Elon Musk{" "}
        </div>{" "}
        <div>
          Next:{" "}
          <a className="footer-link" href="/people">
            What makes Some Successful and others Not ?
          </a>{" "}
        </div>{" "}
      </footer>{" "}
    </motion.div>
  );
}

export default Measuring;

import React from "react";
import { motion } from "framer-motion";
import "./styles/blue-pages.css";
import Nav from "./nav";

function People() {
  return (
    <motion.div
      className="blue"
      initial={{
        opacity: 0,
        x: "-100vw",
      }}
      animate={{
        opacity: 1,
        x: 0,
      }}
      exit={{
        opacity: 0,
        x: "-100vw",
      }}
    >
      <Nav />
      <div className="sec1">
        <div className="people-preview"> </div>{" "}
        <div>
          {" "}
          <h1>What makes Some Successful and others Not?</h1>{" "}
        </div>
      </div>{" "}
      <div className="sec2 seven-three">
        <div>
          <h3>Circumstances.</h3>
          <br />
          <p>
            How people react to certain Circumstances that arise during their
            lifetime, will determine whether they become successful or not.
          </p>
        </div>{" "}
        <div className="svg different-people"> </div>{" "}
      </div>{" "}
      <div className="sec3">
        <div className="svg ambition"> </div>{" "}
        <div>
          <h3>Ambition.</h3>
          <br />
          <p>
            Successful people tend to be ambitious, they have a strong desire to
            succeed.
          </p>
        </div>{" "}
      </div>{" "}
      <div className="sec4">
        <div>
          <h3>Determination.</h3>
          <br />
          <p>
            Not giving up on goals and always staying focused will push you to
            be successful.
          </p>
        </div>{" "}
        <div className="svg determination"> </div>{" "}
      </div>{" "}
      <div className="sec5">
        <div className="svg randomness"> </div>{" "}
        <div>
          {" "}
          <h3>Randomness.</h3>
          <br />
          <p>
            Many times there is a bit of randomness that allows a great idea to
            become successful.{" "}
          </p>
        </div>{" "}
      </div>{" "}
      <footer>
        <div>
          <a className="footer-link" href="/">
            back{" "}
          </a>{" "}
        </div>{" "}
        <div>
          {" "}
          “ Success is when preparation meets opportunity” - Anonymous{" "}
        </div>{" "}
        <div>
          Next :{" "}
          <a className="footer-link" href="/achieve">
            How to achieve success ?
          </a>{" "}
        </div>{" "}
      </footer>{" "}
    </motion.div>
  );
}

export default People;

import React from "react";
import { motion } from "framer-motion";
import "./styles/nav.css";

function Nav() {
  return (
    <motion.div
      className="blue"
      initial={{
        opacity: 0,
        x: "-100vw",
      }}
      animate={{
        opacity: 1,
        x: 0,
      }}
      exit={{
        opacity: 0,
        x: "-100vw",
      }}
    >
      <nav>
        <div>
          <a href="/">
            <h4>Success</h4>
          </a>
        </div>
        <div id="links-align-right">
          <a href="/meaning">
            <p>Its Meaning</p>
          </a>
          <a href="/measuring">
            <p>Measuring It</p>
          </a>
          <a href="/people">
            <p>Some Have It</p>
          </a>
          <a href="/achieve">
            <p>Achieving It</p>
          </a>
        </div>
      </nav>
    </motion.div>
  );
}

export default Nav;

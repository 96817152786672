import React from "react";
import { motion } from "framer-motion";
import "./styles/blue-pages.css";
import Nav from "./nav";

function Achieve() {
  return (
    <motion.div
      className="blue"
      initial={{
        opacity: 0,
        x: "-100vw",
      }}
      animate={{
        opacity: 1,
        x: 0,
      }}
      exit={{
        opacity: 0,
        x: "-100vw",
      }}
    >
      <Nav />
      <div className="sec1">
        <div className="achieve-preview"> </div>{" "}
        <div>
          {" "}
          <h1>How do you achieve success?</h1>{" "}
        </div>{" "}
      </div>{" "}
      <div className="sec2 seven-three">
        <div>
          <h3>Develop the traits needed.</h3>
          <br />
          <p>Determination, Ambition, and Resilience.</p>{" "}
        </div>{" "}
        <div className="svg ambition"> </div>{" "}
      </div>{" "}
      <div className="sec3">
        <div className="people-preview"> </div>{" "}
        <div>
          <h3>Surround yourself with the right people. </h3>
          <br />
          <p>Those who will push you to do better, and to do more!</p>
        </div>{" "}
      </div>{" "}
      <div className="sec4">
        <div>
          <h3>Just Start.</h3>
          <br />
          <p>"Now" is the best time to start working toward your goals.</p>
        </div>{" "}
        <div className="svg determination"> </div>{" "}
      </div>{" "}
      <footer>
        <div>
          <a className="footer-link" href="/">
            back{" "}
          </a>{" "}
        </div>{" "}
        <div>
          {" "}
          “ To give anything less than your best is to sacrifice the gift.” -
          Steve Prefontaine <br />{" "}
        </div>{" "}
        <div>
          Next:{" "}
          <a className="footer-link" href="/meaning">
            What does success mean ?
          </a>{" "}
        </div>{" "}
      </footer>{" "}
    </motion.div>
  );
}

export default Achieve;

import React from "react";
import "./styles/home.css";
import Meaning from "./Meaning";
import Measuring from "./Mesuring";
import People from "./People";
import Achieve from "./Achieve";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

function App() {
  return (
    <Router>
      <div>
        <AnimatePresence exitBeforeEnter>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/meaning" component={Meaning} />
            <Route path="/measuring" component={Measuring} />
            <Route path="/people" component={People} />
            <Route path="/achieve" component={Achieve} />
          </Switch>
        </AnimatePresence>
      </div>{" "}
    </Router>
  );
}

const pageTransition = {
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: "-100vw",
  },
};

function Home() {
  return (
    <motion.div
      className="home-grid"
      initial="out"
      animate="in"
      exit="out"
      variants={pageTransition}
    >
      <h1 className="success-title"> Success </h1>{" "}
      <div className="empty-div-1"> </div>{" "}
      <h3 className="lit-project"> A Lit(erature) Project </h3>{" "}
      <div className="options">
        <a className="meaning-option" href="/meaning">
          <p> What does success </p> <h3> mean </h3> <p> to me ? </p>{" "}
          <div className="meaning-preview"> </div>{" "}
        </a>{" "}
        <a className="measuring-option" href="/measuring">
          <p> How do you </p> <h3> measure </h3> <p> success ? </p>{" "}
          <div className="measurement-preview"> </div>{" "}
        </a>{" "}
        <a className="people-option" href="/people">
          <p> What makes </p> <h3> some people </h3> <p> successful ? </p>{" "}
          <div className="people-preview"> </div>{" "}
        </a>{" "}
        <a className="achieving-option" href="/achieve">
          <p> How do you </p> <h3> achieve </h3> <p> success ? </p>{" "}
          <div className="achieve-preview"> </div>{" "}
        </a>{" "}
      </div>{" "}
      <h5 className="by-james"> By James P </h5>{" "}
      <div className="empty-div-2"> </div>{" "}
      <a href="https://www.jamesdpratt.com" className="visit-site">
        Visit My Site{" "}
      </a>{" "}
    </motion.div>
  );
}

export default App;
